@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400');
@import url('https://fonts.googleapis.com/css?family=Rajdhani');

@import './_variables.scss';

// header
.landing-page {
  .dashboard {
    padding-top: 0;
    background-color: transparent;

    header {
      background-color: $grey1_80;

      @media only screen and (max-width: $screenPhone - 1) {
        background-color: $dark2;
      }
    }
  }
}

header .search-bar.small {
  margin-left: 220px;
}

header {
  display: flex;
  position: fixed;
  justify-content: space-between;
  height: 75px;
  z-index: 1000;
  top: 0;
  align-items: center;
  padding: 1%;
  width: 100%;

  .search-bar {
    position: relative;
    display: flex;
    flex: 2;
    transition: all 0.3s ease;

    @media only screen and (max-width: $screenMedium - 1) {
      display: none;
    }

    img {
      position: absolute;
      left: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    #lincd-search {
      margin: 5px;
      background: $dark-secondary;
      border: transparent;
      padding-left: 50px;
      height: 40px;
      width: 100%;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.6);

      &:before {
        content: '';
        background: url('/images/search.svg') no-repeat left center;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  button {
    margin: 5px;
    font-size: 15px;
    font-weight: 600;
    width: 109px;
    height: 50px;
    padding: 0 !important;
  }
}

// Main banner
.banner-wrapper {
  color: $white;
  background: url('/images/main-bg.png') no-repeat center center;
  background-attachment: fixed;
  background-color: $dark-blue;
  background-size: cover;

  h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 63px;
  }

  p {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 55px;
  }

  // button {
  //     font-weight: 600;
  //     width: 220px;
  //     height: 70px;
  //     font-size: 22px;
  // }
}

// .how-it-works {
//   background-color: $white;
//   padding-top: 60px;
//   padding-bottom: 60px;

//   h2 {
//     font-size: 54px;
//     color: $dark;
//     font-weight: 600;
//     line-height: 68px;
//   }

//   h4 {
//     font-size: 36px;
//     color: $dark;
//     font-weight: 600;
//     margin-bottom: 10px;
//   }

//   .lincd-data-section {
//     flex-direction: column;
//     margin-top: 126px;

//     @media (min-width: $screenMedium) {
//       flex-direction: row;
//     }

//     .col25 {
//       // width: 25%;
//       flex: 1;
//       margin-left: 25px;
//     }

//     .col75 {
//       flex: 2;
//       padding-left: 100px;
//       position: relative;
//       margin-top: 6vh;

//       @media (min-width: $screenPhone) {
//         padding-left: 200px;
//       }

//       @media (min-width: $screenMedium) {
//         margin-top: unset;
//         .left-green-bar {
//           width: 5px;
//           height: 625px;
//           background: $green;
//           position: absolute;
//           left: 100px;
//           top: 57px;
//           z-index: 0;
//         }
//       }

//       .col {
//         margin-bottom: 70px;

//         &:nth-child(2) {
//           .circle {
//             top: 10px;
//           }
//         }

//         &:nth-child(3) {
//           .circle {
//             top: -10px;
//           }
//         }

//         &:nth-child(4) {
//           .circle {
//             top: -10px;
//           }
//         }

//         .circle {
//           width: 60px;
//           height: 60px;
//           position: absolute;
//           left: -86px;

//           background: $green;
//           border-radius: 50%;
//           display: flex;
//           align-items: center;
//           justify-content: center;

//           @media (min-width: $screenMedium) {
//             left: -128px;
//           }

//           .horizontal-green-bar {
//             display: unset;
//             height: 5px;
//             background: $green;
//             position: absolute;

//             width: 90px;
//             left: -89px;
//             @media (min-width: $screenPhone) {
//               width: 200px;
//               left: -200px;
//             }

//             @media (min-width: $screenMedium) {
//               display: none;
//               top: 57px;
//               left: -128px;
//             }
//           }
//         }
//       }

//       .data-source {
//         padding-top: 2vh;
//       }

//       .mapping-tool {
//       }

//       .graph-storage {
//       }
//     }
//   }

//   .lincd-registry {
//     background-color: $dark-secondary;
//     max-width: 957px;
//     margin: 0 auto;
//     border-radius: 11px;

//     width: 85vw;

//     flex-direction: column;

//     .col {
//       width: 437px;
//     }

//     .col:first-child {
//       padding-bottom: 3vh;

//       width: unset;
//       margin: 0 10vw;

//       @media (min-width: $screenPhone) {
//         margin-left: 15vw;
//         width: 375px;
//       }

//       @media (min-width: $screenTablet) {
//         width: 437px;
//         margin: unset;
//       }
//     }

//     .col a {
//       left: calc(50% - 75px);
//       position: relative;
//       top: 2vh;

//       @media (min-width: $screenMedium) {
//         left: 0;
//       }
//     }

//     @media (min-width: $screenTablet) {
//       width: unset;
//     }

//     @media (min-width: $screenMedium) {
//       flex-direction: row;
//       z-index: 10;

//       .col:first-child {
//         margin-bottom: unset;
//       }
//     }

//     h2 {
//       font-size: 54px;
//       color: $white;

//       .small-text {
//         font-size: 30px;
//         line-height: 44px;
//         display: block;
//         letter-spacing: 3px;
//         font-weight: 400;
//         text-transform: uppercase;
//       }

//       .blue-char {
//         color: $secondary-color;
//       }
//     }

//     p {
//       font-size: 16px;
//       line-height: 24px;
//       font-weight: 400;
//       margin-top: 15px;
//       margin-bottom: 15px;
//       color: $white;
//       padding-right: 15px;

//       text-align: center;

//       @media (min-width: $screenTablet) {
//         text-align: unset;
//       }
//     }

//     button {
//       padding: 20px 40px;
//       font-size: 22px;
//     }

//     .blue-char {
//       color: $blue;
//     }

//     .js-code {
//       background-color: $dark-bg;
//       padding: 30px;
//       border-radius: 5px;
//       margin-top: 5vh;

//       max-width: 70vw;

//       @media (min-width: $screenMedium) {
//         margin-top: 0vh;
//       }

//       h4 {
//         font-size: 36px;
//         line-height: 44px;
//         font-weight: 600;
//         color: $white;
//         text-align: center;
//       }

//       img {
//         max-width: 60vw;

//         @media (min-width: $screenTablet) {
//           max-width: unset;
//         }
//       }
//     }
//   }
// }

// // Join challenges
// .join-challenges {
//   background: url(/images/challenges-bg.png) no-repeat top center;
//   background-color: $white;
//   background-size: cover;
//   display: flex;
//   justify-content: flex-end;

//   .container {
//     .globe-img {
//       position: absolute;
//       right: 640px;

//       img {
//         width: 550px;
//       }
//     }

//     .dark-box {
//       border-radius: 10px;
//       max-width: 604px;
//       width: 100%;
//       z-index: 1;
//       background-color: $dark-secondary;

//       p {
//         text-align: center;

//         @media (min-width: $screenTablet) {
//           text-align: unset;
//         }
//       }

//       h4 {
//         font-weight: 600;
//         font-size: 54px;
//         line-height: 69px;

//         text-align: center;

//         @media (min-width: $screenTablet) {
//           text-align: unset;
//         }
//       }

//       .lincd-account {
//         margin-top: 100px;
//       }

//       button {
//         margin-right: 10px;
//         border-radius: 4px;
//         font-size: 15px;
//         font-weight: 600;
//       }
//     }
//   }
// }

//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
///                                                ///
///           UPDATED LANDING PAGE                 ///
///                                                ///
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

.landing-page-button {
  background-color: $blue-buttons;
  color: white;

  cursor: pointer;

  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 300;

  border-radius: 6px;
  height: 52px;
  width: 192px;

  transition: background-color 0.2s;

  &:hover {
    background-color: $dark-grey;

    transition: background-color 0.2s;
  }
}

/////////////////
// new header //
///////////////
.header-left-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  @media only screen and (max-width: $screenMedium - 1) {
    span {
      display: none;
    }
  }

  .menu a {
    padding-right: 1.5rem;
  }

  a {
    text-decoration: none;
    padding-right: 10px;
  }

  span {
    color: $text1_80;
    font-family: $montserrat;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      color: $text1;
    }
  }
}

.header-right-nav {
  .search-bar,
  .header-join-us {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .burger-menu {
    display: flex;

    svg {
      margin-right: 25px;
      height: 32px;
      width: 32px;
    }

    span {
      color: $text1_80;
      font-family: $montserrat;
      font-size: 20px;
      font-weight: 500;
    }

    #burger-menu-links {
      background-color: $dark2;
      border-top: $blue-light-bg solid 3px;

      display: flex;
      align-items: center;
      flex-direction: column;

      position: absolute;
      top: 75px;
      left: 0;

      width: 100vw;

      .menu-join-us {
        font-size: 18px;
        margin: 2vh 0 3vh;
      }

      & .menu-select {
        cursor: pointer;
        text-align: center;
        padding: 2vh 0;
        width: 100vw;

        &:hover {
          background-color: $dark-secondary;
        }
      }
    }
  }

  @media only screen and (min-width: $screenMedium) {
    .search-bar,
    .header-join-us {
      display: initial;
    }

    display: flex;
    align-items: center;
    justify-content: flex-end;

    //width: 40vw;
    width: calc(100vw - 230px);

    //transition: width 0.4s;

    .burger-menu {
      display: none;
    }
  }

  .header-login {
    background-color: #17181c;
  }

  button {
    font-size: 15px;
    font-weight: 300;

    height: 40px;
    width: 130px;
  }
}

///////////
// hero //
/////////
.hero {
  // background-color: #131417;
  background: url('/images/hero-background2.svg') no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  background-color: #000000;

  height: 100vh;
  width: 100%;

  .get-started-section {
    padding-left: 10vw;
    padding-top: 20vh;
    padding-right: 10vw;
    position: relative;

    h1 {
      font-family: 'Montserrat';
      font-size: 56px;

      //padding-right: 2vw;
      max-width: 18ch;
    }

    h2 {
      font-family: 'Montserrat';
      font-size: 26px;
      font-weight: 200;

      position: relative;
      //padding-right: 2vw;
      top: 1vh;

      max-width: 32ch;
    }

    button {
      position: relative;
      top: 5vh;
    }
  }

  .scroll-to-build-apps {
    cursor: pointer;
    position: absolute;
    left: calc(50vw - 28px);
    top: 87vh;
  }
}

////////////////////
// first section //
//////////////////
#build-apps {
  background-color: #f5f5f5;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media only screen and (max-width: $screenTablet - 1) {
    height: fit-content;
    padding-bottom: 0;
  }

  h2 {
    font-family: 'Montserrat';
    font-size: 40px;
    color: black;
    margin-bottom: 24px;
    font-weight: 400;
    letter-spacing: -0.015em;
  }

  p {
    font-family: 'Montserrat';
    font-size: 20px;
  }

  .build-description {
    position: relative;
    padding: 0 10vw;
    top: 17vh;
    text-align: left;
    z-index: 100;

    @media only screen and (max-width: $screenMedium - 1) {
      text-align: center;
    }
    @media (min-width: $screenMedium) {
      br {
        display: none;
        margin: 0;
      }
    }
    @media (min-width: $screenLarge) {
      padding: 0 17vw;
    }
  }

  .info-cards {
    display: flex;
    position: relative;
    padding-top: 25vh;
    padding-bottom: 15vh;
    gap: 5vh;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;

    @media only screen and (max-width: $screenTablet - 1) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 360px;
      justify-content: space-between;

      .header-group {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      svg {
        width: 158px;

        @media only screen and (max-width: $screenMedium - 1) {
          width: 140px;
        }

        @media only screen and (max-width: $screenTablet - 1) {
          width: 128px;
        }
      }

      h3 {
        font-family: 'Montserrat';
        font-size: 32px;
        margin-bottom: 2vh;

        @media only screen and (max-width: $screenMedium - 1) {
          font-size: 30px;
        }

        @media only screen and (max-width: $screenTablet - 1) {
          font-size: 28px;
        }
      }

      p {
        font-family: 'Montserrat';
        font-size: 20px;
        // max-width: 25vw;
        text-align: center;

        @media only screen and (max-width: $screenMedium - 1) {
          font-size: 18px;
        }

        @media only screen and (max-width: $screenTablet - 1) {
          font-size: 16px;
          max-width: 50vw;

          &:not(.description) {
            max-width: unset;
          }
        }

        @media only screen and (max-width: $screenPhone - 1) {
          max-width: 75vw;
        }
      }

      button {
        position: relative;
        margin: 2vh;
      }

      .ontology-stats {
        display: block;
        white-space: nowrap;
        width: max-content;

        span {
          color: $blue-buttons;
        }
      }
    }
  }
}

///////////////////
// how it works //
/////////////////
#how-it-works {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .heading-text {
    padding: 13vh 15vw 0vh 15vw;
    text-align: left;
    background-color: $dark2;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $screenMedium - 1) {
      text-align: center;
      padding: 13vh 5vw 5vh;
    }

    h3 {
      color: white;
      font-family: 'Montserrat';
      font-size: 20px;
    }

    h2 {
      color: white;
      font-family: 'Montserrat';
      font-size: 40px;
      margin-bottom: 24px;
      font-weight: 400;
      letter-spacing: -0.015em;
    }
  }

  .section {
    min-height: 62vh;
    @media (max-width: $screenPhone) {
      min-height: 75vh;
    }
    justify-content: center;
    display: flex;

    .content-wrapper {
      align-items: center;
      display: grid;
      justify-items: center;
      //grid-auto-columns: minmax(0, 1fr);
      grid-auto-columns: 8fr 6fr;
      grid-auto-flow: column;
      grid-template-areas: 'img des';

      .images {
        display: inline-flex;
        grid-area: img;

        width: 100%;
        height: 100%;
        padding: 0 0 0 5vw;
        text-align: center;

        > div {
          background-repeat: no-repeat;
          background-position: center;
          width: 300px;
          height: 300px;
          background-size: contain;
          //max-width:48vw;
          width: 100%;
          height: auto;
        }
        .how-it-works-image-1 {
          background-image: url('/images/how-it-works-1.png');
          //width:621px;
          //height:283px;
        }
        .how-it-works-image-2 {
          background-image: url('/images/how-it-works-2.png');
          //width:618px;
          //height:351px;
        }
        .how-it-works-image-3 {
          background-image: url('/images/how-it-works-3.png');
          //width: 777px;
          //height:420px;
        }
      }

      @media only screen and (max-width: $screenMedium - 1) {
        grid-template-areas:
          'des'
          'img';
        row-gap: 2vh;
        //grid-auto-rows: minmax(0, 1fr);
        //grid-auto-flow: row;

        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        padding: 5vh 0;

        .images {
          padding: 0 9vw 5vh 9vw;
          min-height: 150px;
        }
      }

      @media only screen and (max-width: $screenTablet - 1) {
        grid-template-areas:
          'des'
          'img';
        grid-auto-rows: minmax(0, 1fr);
        grid-auto-flow: row;
      }

      svg {
        margin-bottom: 0;
        width: 288px;

        @media only screen and (max-width: $screenMedium - 1) {
          margin-bottom: 8vh;
        }

        @media only screen and (max-width: $screenTablet - 1) {
          margin-bottom: 8vh;
          width: 240px;
        }
      }

      .description {
        padding: 5vh 7vw 5vh;
        grid-area: des;

        @media only screen and (max-width: $screenMedium - 1) {
          text-align: center;
          width: 100vw;
        }

        @media only screen and (max-width: $screenTablet - 1) {
          text-align: center;
          width: 100vw;
        }

        .subheading {
          font-family: 'Montserrat';
          font-size: 24px;
          font-weight: 700;
          margin: 0 0 3vh 0;
        }

        .subtext {
          font-family: 'Montserrat';
          font-size: 16px;
          word-wrap: normal;
        }

        p {
          color: white;
        }
      }
    }
  }

  .data-insights {
    background-color: $dark2;
  }

  .shapes {
    background-color: #252830;

    .content-wrapper {
      grid-template-areas: 'des img';
      grid-auto-columns: 6fr 8fr;
      .images {
        padding: 0 5vw 0 0;
      }

      @media only screen and (max-width: $screenMedium - 1) {
        //grid-template-columns: 1fr;
        //grid-template-rows: 1fr;
        grid-template-areas:
          'des'
          'img';
        .images {
          padding: 0 5vw 5vh 5vw;
        }
      }

      @media only screen and (max-width: $screenTablet - 1) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
          'des'
          'img';
      }
    }
  }

  .components {
    background-color: $dark2;
  }
}

//////////////////////////
// build LINCD modules //
////////////////////////
#build-modules {
  //fixing footer black padding for homepage
  margin-bottom: -4rem;

  .body-k {
    gap: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 120px;
    background: white;
  }

  .autoboxall {
    gap: 70px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
  }

  .autobox {
    gap: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
  }

  .headersub {
    gap: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;

    h2 {
      font-family: 'Montserrat';
      font-size: 40px;
      color: black;
      margin-bottom: 24px;
      font-weight: 400;
      letter-spacing: -0.015em;
    }
  }

  .contentframe {
    gap: 30px;
    position: relative;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    padding: 0px;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .componentspersonframe {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
  }

  .shapespersonframe {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: min(80vw, 690px);
    height: 425px;
    padding: 0px;
  }

  .srccomponentspersonview {
    letter-spacing: -2%;
    width: 277px;
    height: 37px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(90, 90, 90, 1);
  }

  .codeWrapper {
    max-width: 100%;
  }
  .personviewcode {
    background-color: $darcula-bg;

    gap: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    //min-height: 390px;
    max-width: min(80vw, 690px);
    padding: 16px;
    border-radius: 9px;
  }

  .srcshapesperson {
    letter-spacing: -2%;
    height: 37px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(90, 90, 90, 1);
  }

  .shapesperson {
    background-color: $darcula-bg;

    gap: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 388px;
    padding: 16px;
    border-radius: 9px;
    max-width: min(80vw, 690px);
  }

  .image-57 {
    width: 628px;
    height: 285px;
    background: url('/images/image 57.png');
  }

  .image-56 {
    width: 628px;
    height: 349px;
    background: url('/images/image 56.png');
  }

  @media (max-width: 810px) {
    .image-57 {
      width: 428px;
    }

    .image-56 {
      width: 428px;
    }

    .body-k {
      padding: 75px;
    }
  }
}
