.build-with-lincd {
  height: 360px;
  background: $blue;
  padding: 50px 0;
  min-height: 600px;
  background: rgb(16, 30, 44);
  background: linear-gradient(180deg, rgba(16, 30, 44, 1) 0%, rgba(47, 68, 92, 1) 100%);
  border-bottom: 10px solid $secondary-color;

  .builtlinked {
    border-bottom: 10px solid $secondary-color;

    h3 {
      font-size: 54px;
      margin: 40px 0;
      color: $white;
      font-weight: 600;
      line-height: 69px;
      font-weight: 600;
    }
  }

  .container {
    position: relative;

    .slider {
      position: absolute;

      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 300px;

      //top: 230px;
      top: 135px;
    }

    .wrapper {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 400px;
      z-index: 1;
    }

    .slides {
      display: flex;
      position: relative;
      top: 0;
      left: 0;
      width: 10000px;
    }

    .slides.shifting {
      transition: left 0.2s ease-out;
    }

    .slide {
      width: 300px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: all 1s;
      position: relative;
      border-radius: 2px;
      //padding: 0 65px;
      padding: 0 15px;

      .col {
        min-height: 270px;
        color: $white;
        padding: 30px;
        background: $blue-dark;
        // border: 2px solid $blue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // box-shadow: ;
        margin: 0 10px;
        box-shadow: 20px 20px 15px rgba(0, 0, 0, 0.3);

        // &:hover {
        //     border: 2px solid $secondary-color
        // }

        h5 {
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;
        }

        h4 {
          font-size: 32px;
          margin-top: 20px;
          margin-bottom: 10px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          font-weight: 200;
        }

        // .bottom {
        //     margin-top: 50px;
        // }
      }
    }

    .slider.loaded {
    }

    .control {
      background: rgba(65, 208, 244, 0.3);
      position: absolute;
      top: 40%;
      width: 125px;
      height: 125px;
      // background: rgb(65, 208, 244, .3);
      margin-top: -20px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
      z-index: 2;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      svg {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;

        path {
          fill: $white;
          opacity: 1;
        }
      }
    }

    .prev,
    .next {
      background-size: 22px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      // position: relative;
    }

    .prev {
      left: 25px;
    }

    .next {
      right: -25px;

      svg {
        transform: rotate(180deg);
      }
    }

    .prev:active,
    .next:active {
      transform: scale(0.8);
    }

    .dots i {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: black;
      display: none;
    }

    .dots i.active {
      background: white;
    }
  }
}
