.dashboard {
  padding-top: 120px;
  background-color: $dark-secondary;

  .content {
    display: none;
    opacity: 0;

    -webkit-animation: display-none-transition 1s ease;
    animation: display-none-transition 1s ease;
  }
}

@keyframes display-none-transition {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes display-none-transition {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
  }

  100% {
    opacity: 1;
  }
}

.content-section {
  display: flex;
  justify-content: left;

  padding-bottom: 20px;

  .content {
    .row {
      display: flex;
      margin-bottom: 50px;
    }
  }
}

.pagination {
  .col {
    display: flex;
    width: auto;
    background-color: $dark2;
    padding: 10px;
    margin-bottom: 5%;
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;

    p {
      color: $mid-grey;
      font-size: 13px;
    }

    nav {
      display: flex;
      align-items: center;

      a {
        color: $white;
        text-decoration: none;
        padding: 4px 9px;
        background-color: $dark-grey;
        margin-left: 1px;
        height: 40px;
        height: 24px;
        display: flex;
        align-items: center;

        .chevron {
          font-size: 20px;
        }
      }
    }
  }
}

// /* The Modal (background) */
// .ebcf_modal {
//     display: none; /* Hidden by default */
//     position: fixed; /* Stay in place */
//     z-index: 1; /* Sit on top */
//     padding-top: 100px; /* Location of the box */
//     left: 0;
//     top: 0;
//     width: 100%; /* Full width */
//     height: 100%; /* Full height */
//     overflow: auto; /* Enable scroll if needed */
//     background-color: rgb(0,0,0); /* Fallback color */
//     background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
// }

// /* Modal Content */
// .ebcf_modal-content {
//     background-color: #fefefe;
//     margin: auto;
//     padding: 20px;
//     border: 1px solid #888;
//     width: 80%;
// }

// /* The Close Button */
// .ebcf_close {
//     color: #aaaaaa;
//     float: right;
//     font-size: 28px;
//     font-weight: bold;
// }

// .ebcf_close:hover,
// .ebcf_close:focus {
//     color: $black;
//     text-decoration: none;
//     cursor: pointer;
// }

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.modal-content {
  align-self: center;
  background-color: white;
  padding: 1rem 1.5rem;
  width: 24rem;
  border-radius: 0.5rem;
}

.btn-close {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: lightgray;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
