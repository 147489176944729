@import '../scss/variables';

.alert {
  padding: 1rem;
  margin: 1rem 0;

  &.warning {
    background-color: $warningBg;
    color: $warningText;
  }

  &.success {
    background-color: $successBg;
    color: $successText;
  }

  &.error {
    background-color: $errorBg;
    color: $errorText;
  }

  &.info {
    background-color: $infoBg;
    color: $infoText;
  }
}
