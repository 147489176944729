@import '../scss/variables';

// .search-bar {
//   > img {
//     border: 1px $blue-buttons solid;
//     border-right: none;
//     @media only screen and (min-width: $screenTablet) {
//       border: initial;
//     }
//   }
//   > div {
//     border: 1px $blue-buttons solid;
//     border-left: none;
//     @media only screen and (min-width: $screenTablet) {
//       border: initial;
//     }
//   }
// }

#lincd-search {
  border-radius: 0 4px 4px 0 !important;
  @media only screen and (min-width: $screenTablet) {
    border-radius: initial;
  }
}

.lincd__menu {
  padding: 0;
  margin: 0;

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $mid-grey;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-corner {
    visibility: hidden;
  }
}

.lincd__menu {
  min-width: 300px;
  max-width: 300px;
  @media only screen and (min-width: $screenTablet) {
    max-width: none;
  }
  .lincd__menu-list {
    max-height: 20vh;
    .lincd__option {
      color: rgba(255, 255, 255, 0.8);
      width: calc(100% - 12px);
      margin: 0 6px;
    }

    .lincd__option--is-focused {
      border-radius: 10px;
      color: $paragraph-body;
    }
  }
}
