// TODO: @import 'browse';

@import 'variables';
@import '_grids';
@import '_utils';
@import 'landing-page';
@import 'builtwithlincd';
@import 'sidenav';
@import 'dashboard';
@import 'ontology';
@import 'component';
@import 'module';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&family=Rajdhani:wght@300;400;500;600;700&display=swap');

.coming-soon {
  color: white;
  display: table;
  font-size: 64px;
  margin: 0 auto;
  position: relative;
  top: 30vh;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

html {
  position: relative;
  scroll-behavior: smooth;
  min-height: 100%;
}

body {
  background-color: $dark-secondary;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rajdhani', sans-serif;
}

p,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

*,
::after,
::before {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

input {
  margin: 5px;
  background: #202124;
  border: transparent;
  padding: 12px 12px;
  height: 19.2px;
  height: auto;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  border: 1px solid #4c4c4c;

  &[type='text'] {
    width: 100%;
  }

  &:focus {
    outline: none;
    background: #37383b;
    border: 1px solid #656565;
  }

  &::placeholder {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.6);
  }
}

code {
  background-color: inherit;
  border: 0;
  color: white;
  line-height: 1.2rem;
  font-size: 14px;
}

pre {
  background-color: $mid-grey;
}

.cursor-pointer {
  cursor: pointer;
}

// Just putting this here, since module, ontology and component.scss need it
.action_bar {
  padding: 15px;
  background-color: $dark2;
  display: flex;
  justify-content: space-between;

  & .js-btn {
    background-color: $mid-grey;
    border: 0px;
    color: $white;
  }

  & .js-btn:hover {
    background-color: $light-mid-grey;
    transition: 0.3s;
  }

  & .result-btn {
    background-color: $mid-grey;
    color: $white;
    border: 0px;
    border-top: 4px solid $light-grey;
  }
}

.content {
  color: black;

  flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;

  padding-bottom: 4rem;
}

.main {
  top: 0;
  width: 100%;
  z-index: 100;

  & .main-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;

    .main-middle {
      width: 100%;

      & .main-middle-container {
        background-color: $dark-grey;
        height: auto;
        margin: 50px auto;
        border-radius: 10px;

        & .license {
          padding: 0 20px;

          & .license-container {
            background-color: $dark2;
            height: 120px;
            overflow-y: auto;
            padding: 20px;
            margin-bottom: 30px;

            & p {
              color: white;
              font-size: small;
            }
          }
        }

        .main-middle-hdr {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          & .right img {
            margin-right: 20px;
          }

          & .right .view-in-editor {
            border: 0;
            background-color: inherit;
            color: $white;
            margin-right: 10px;
            font-size: small;
            margin-right: 20px;
            border-radius: 3px;
            font-size: 14px;
          }

          & .right .view-in-editor:hover {
            background-color: $blue-light-bg;
            transition: 0.3s;
          }

          & .post-title {
            color: $white;
            margin-bottom: 2px;
          }

          & .post-author {
            margin-right: 10px;
          }

          & .post-author-avatar {
            margin-right: 10px;
            //color:$white;
            color: #ffffff3d;
            font-size: 3rem;
            svg {
              vertical-align: top;
            }
          }

          & .author-details {
            display: flex;
            padding-left: 25px;
            padding-top: 25px;
          }
        }
      }
    }
  }
}

.follow-btn {
  padding: 2px 10px;
  background-color: $green-dark;
  border: 0;
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px;
}

.follow-btn:hover {
  background-color: $green-highlight;
  transition: 0.3s;
}

.sharebox {
  background-color: $dark2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;

  & p {
    text-transform: uppercase;
    color: $mid-grey;
  }

  & button {
    background-color: $dark-grey;
    padding: 5px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    border-radius: 0px 5px 5px 0px;

    & .copylink {
      font-size: 9px;
      margin-left: 5px;
    }
  }

  & button:hover {
    background-color: $mid-grey2;
  }

  & .social-icon {
    background-color: $dark-grey;
    padding: 1px 5px 3px 5px;
    margin-right: 2px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & img {
      margin-top: 5px;
    }

    & span {
      margin-top: 5px;
    }

    & .copylink {
      font-size: 10px;
      margin-left: 5px;
      color: $white;
    }
  }

  & .social-icon:hover {
    background-color: $mid-grey2;
  }
}

// Module, component and ontology.scss all have this
.tags {
  display: flex;
  margin-top: 40px;
}

.published-date {
  margin: 20px 20px 0 0;
  text-align: right;
  @media (max-width: $screenTablet) {
    text-align: left !important;
    margin-left: 25px;
    margin-top: 20px;
  }

  p {
    font-size: 16px;
  }
}

.codeWrapper,
.post-content-area {
  ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $mid-grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    visibility: hidden;
  }
}

/*
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $main-bg;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $light-grey;
}*/

.results {
  color: $white;
}

.text-white {
  color: #fff;

  a {
    color: $light-purple;
  }

  a:active,
  a:hover {
    color: $mid-purple;
  }
}

.text-grey {
  color: #b5b9c4;
}

main {
  display: flex;
  width: 100%;
  flex-direction: column;

  background-color: #131417;
  position: absolute;
  // width: calc(100vw - 240px);
  min-height: 100vh;
  right: 0px;
  overflow-x: hidden;
  transition: width 0.3s ease;

  &.open-sidebar {
    width: calc(100vw - 220px);
  }
}

a {
  color: $mid-purple;
}

a:active,
a:hover {
  color: $light-purple;
}
.header {
  margin: 10px 0px;
}
.min-w-screen-md {
  display: none;
  @media (min-width: $screenMedium) {
    display: block;
  }
}
.min-w-screen-tablet {
  display: none;
  @media (min-width: $screenTablet) {
    display: block;
  }
}
.min-w-screen-phone {
  display: none;
  @media (min-width: $screenPhone) {
    display: block;
  }
}
.max-w-screen-tablet {
  //{typeof window !== 'undefined' && window.innerWidth <= 767 && (
  display: none;
  @media (max-width: $screenTablet - 1) {
    display: block;
  }
}
.max-w-screen-medium {
  //{typeof window !== 'undefined' && window.innerWidth <= 767 && (
  display: none;
  @media (max-width: $screenMedium - 1) {
    display: block;
  }
}
.sidenav-container:not(.isHome) {
  display: block !important;
}
