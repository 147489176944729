@import './_variables.scss';

.footer {
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -3px 6px #ffffff40;
}

.footer-a {
  gap: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 75px 61px 100px;
  background: $grey1;
  @media(max-width:$screenPhone) {
    padding: 50px 20px 50px;
  }
}

.footerGroup {
  gap: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
}

.footerGrid {
  position: relative;
  display: grid;
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20px;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media only screen and (max-width: $screenMedium - 1) {
    grid-template-columns: repeat(3, 1fr);

    .list-head-1 {
      padding-left: 20px;
    }

    .footerlist {
      padding-left: 20px;
    }
  }

  @media only screen and (max-width: $screenTablet - 1) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 4vh;

    .logo-icon-lincd {
      grid-area: 1 / 1 / 2 / 3 !important;
    }

    .list-head-1 {
      padding-left: 20px;
    }

    .footerlist {
      padding-left: 20px;
    }
  }
  @media only screen and (max-width: $screenPhone) {
    grid-template-columns: 1fr;
    grid-template-rows: initial;
  }

  .logo-icon-lincd {
    gap: 7.752951145172119px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 75px;
    padding: 15px;
    grid-area: 1 / 1 / 2 / 5;

    &:hover {
      cursor: pointer;
    }
  }

  .footerListGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 150px;
    padding: 0px;
  }

  .loginbuttons {
    gap: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 10px 10px;
    min-width: 150px;
  }
}

.frame-36654 {
  gap: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 20px;
  @media(max-width:$screenPhone) {
    padding:0px;
  }
}

.rectangle {
  align-self: stretch;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
}

.frame-36650 {
  position: relative;
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  padding: 0px 10px;
  flex-wrap: wrap;
}

.icon-lincd {
  width: 45.5px;
  height: 19px;
  padding: 0px;
}

.lincd {
  letter-spacing: 0.14em;
  font-family: 'Rajdhani', sans-serif;
  font-size: 27.8px;
  font-weight: 600;
  color: white;

  & span {
    font-family: inherit;
    color: $brand-color;
  }
}

.lincd-inc-2022-we-love-our-users {
  width: 301px;
  height: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: $text1_80;
  cursor: default;
}

.frame-36648 {
  gap: 30px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 44px;
  padding: 0px;
}

.keybutton {
  position: relative;
  width: auto;
  height: 40px;
  padding: 0px;
}

.login {
  position: relative;
  width: 130px;
  height: 40px;
  border-radius: 6px;
}

.follow-us {
  font-size: 15px;
  line-height: 24px;
  color: $text1_80;
  cursor: default;
}

.frame-4 {
  gap: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & div:hover {
    cursor: pointer;
  }
}

.footerlist {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 116px;
  padding: 10px;
}

.list-item-1 {
  align-self: stretch;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: $text1_80;

  &:hover {
    cursor: pointer;
    color: $text1;
  }
}

.rectangle-2 {
  display: flex;
  width: 130px;
  height: 40px;
  top: 0px;
  left: 0px;
  border-radius: 6px;
  text-align: center;
  justify-content: center;
  position: absolute;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  align-items: center;
  color: $text1;
  background: $button-color1;

  &:hover {
    background: $button-color2;
    cursor: pointer;
  }
}

.log-in {
  display: flex;
  width: 130px;
  height: 40px;
  top: 0px;
  left: 0px;
  border-radius: 6px;
  text-align: center;
  justify-content: center;
  position: absolute;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  align-items: center;
  color: white;
  background: $grey_1;

  &:hover {
    background: $button-color2;
    cursor: pointer;
  }
}

.linkedin {
  position: relative;
  width: 23.5px;
  height: 23.5px;
  padding: 0px;
}

.github {
  position: relative;
  width: 24.1px;
  height: 23.5px;
  padding: 0px;
}

.twitter-2 {
  position: relative;
  width: 28.6px;
  height: 23.5px;
  padding: 0px;
  overflow: hidden;
}

.discord-2 {
  position: relative;
  width: 30.4px;
  height: 23.5px;
  padding: 0px;
  overflow: hidden;
}

.list-head-1 {
  height: 24px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: $text1;
  gap: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  height: 34px;
  padding: 0px 10px 10px;
  cursor: default;
}
