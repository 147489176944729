@import 'variables';

aside {
  background: $dark-blue2;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;

  a {
    font-size: 16px;
    color: $white;
    font-family: $montserrat;
    font-weight: 500;
    text-decoration: none;
    line-height: 23px;
    color: $text1_80;
  }

  button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    background: $dark-blue2;
    color: $white;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    border-bottom-right-radius: 5px;

    -webkit-border-top-right-radius: 0px;
    -moz-border-radius-topright: 0px;
    border-top-right-radius: 0px;

    // position: relative;
    // right: 0px;
    // top: 120px;

    //position: absolute;
    //top: 0px;
    //left: 100%;

    position: absolute;
    top: 0px;
    width: 34px;
    height: 34px;

    //left: 0;

    right: -34px;

    @media (min-width: $screenTablet+1) {
      //position: absolute;
      //top: 0px;
      //left: 100%;

      width: 34px;
      height: 34px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topright: 5px;
      border-top-right-radius: 5px;
    }
  }

  .nav-upper-links {
    margin-bottom: 20px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;

    a {
      padding: 10px;

      text-transform: capitalize;

      @media (min-width: $screenMedium - 1) {
        padding-left: 30px;
      }
    }
  }

  nav a {
    display: block;
    text-decoration: none;
    padding: 15px;
    transition: 0.2s all;

    &:hover {
      transition: 0.2s all;
      color: $text1;
    }

    img {
      padding-left: 5px;
    }
  }

  .social-media {
    justify-content: space-around;
    width: 140px;
    margin: 10px auto;
    position: absolute;
    bottom: 10vh;
    left: calc(50% - 70px);

    @media (min-width: $screenMedium) {
      left: 40px;
      bottom: 10px;
    }

    a {
      flex: 1;
      text-align: center;

      &:hover {
        svg path {
          &:hover {
            fill: $secondary-color;
          }
        }
      }
    }
  }
}

// aside.close {
//     left: 0;
//     transition: .3s;
//     transition-timing-function: cubic-bezier(0.9, 0, 1, 1);
// }

$sidenav-width: 220px;
//$sidenav-transition: margin-left ease 0.3s;
$sidenav-transition: margin-left ease 0.3s;

.wrapper {
  position: relative;
  display: flex;
  // min-height: 100vh;
  // overflow-x: hidden;
}

.sidenav {
  height: 100%;
  position: fixed;
  top: 0;
  // left: -254px;

  width: 100%;
  //margin-left: -100%;
  left: -100vw;
  //transition: $sidenav-transition;
  transition: left ease 0.3s;

  @media only screen and (min-width: $screenMedium) {
    min-width: $sidenav-width;
    max-width: $sidenav-width;
    left: -220px;
    //margin-left: -220px;
  }
}

.sidenav-top {
  width: 100%;
  text-align: center;

  a.active-tab {
    background-color: $mid-grey2;
    border-left-color: $blue-light-bg;
    border-left-width: 4px;
  }

  @media only screen and (min-width: $screenMedium) {
    display: unset;
    text-align: left;
  }

  .logo {
    > div > svg {
      display: block;
      position: absolute;

      width: 32px;
      height: 32px;

      right: 25px;
      top: 20px;

      @media only screen and (min-width: $screenMedium) {
        display: none;
      }
    }
  }

  #browse-dropdown {
    cursor: pointer;
    > img {
      position: absolute;
      rotate: 0deg;
      transform-origin: 60% 60%;
    }

    &.dropdown-is-open {
      > img {
        rotate: 180deg;
      }
    }
  }

  > .search-bar {
    display: inline-flex;
    flex-direction: row-reverse;
    margin-top: 15px;
    width: 300px;
    justify-content: center;

    .lincd__control {
      min-width: 300px;
    }

    @media only screen and (min-width: $screenMedium) {
      display: none;
    }
  }
}

.dashboard {
  flex: 1;
  //transition: $sidenav-transition;
}

// # modifiers
.sidenav--overlay {
  position: absolute;
  height: 100%;
}

.sidenav--push + .dashboard {
  min-width: 100%;
}

.sidenav--reveal {
  position: absolute;
  height: 100%;
  z-index: 98;
  margin-left: 0;
}

.sidenav--reveal + .dashboard {
  z-index: 99;
}

// # states
.sidenav.active-aside {
  margin-left: 0;
  height: 100%;
  left: 0px;

  button {
    right: 0px;
    @media (min-width: $screenTablet+1) {
      right: -34px;
      top: 82px;
      //position:relative;
      //display: unset;
      //left: initial;
    }
  }
}

.sidenav.closed button {
  top: 82px;
}

.sidenav--reveal.active-aside + .dashboard {
  transform: translate3d($sidenav-width, 0, 0);
}

.sidenav {
  display: flex;
  flex-direction: column;
}

.dashboard {
  display: flex;
  flex-direction: column;
}

.options {
  background-color: $blue-lighter;
  display: flex;
  align-items: center;
}

.callout--info {
  border-left: 3px solid $blue-lighter;
}

code {
  background-color: $white;
  border: 1px solid $white;
  padding: 0.2em 0.3em;
  border-radius: 2px;
  font-family: monospace;
  color: $mid-grey2;
  margin: 0 0.2em;
}

strong {
  font-weight: 600;
}
