.text-white {
  color: $white;
}

.text-grey {
  color: $light-grey;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: bold;
}

button {
  cursor: pointer;
  font-family: 'Rajdhani', sans-serif;
  font-weight: normal;
  padding: 15px 20px;
}

.margin-0auto {
  margin: 0 auto;
}

.p1 {
  padding: 10px;
}

.p2 {
  padding: 20px;
}

.p3 {
  padding: 30px;
}

.p4 {
  padding: 40px;
}

.pl-4 {
  padding-left: 80px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.btn-blue-outline {
  border: 2px solid $secondary-color;
  background: transparent;
  color: $white;
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 4px;
  font-size: 18px;
  white-space: nowrap;

  transition:0.3s background, color;

  &:hover {
    transition:0.3s background, color;
    background: $secondary-color;
  }

  &:disabled {
    transition:0.3s background, color;
    border: 2px solid $mid-grey;
    color: $mid-grey;

    &:hover {
      background: $dark;
      cursor: not-allowed;
    }
  }
}

.btn-big {
  width: 221px;
  height: 70px;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.btn-no-border {
  border: none;
}

.h-md-100 {
  height: 100vh;
}

.position-relative {
  position: relative;
}
