.overflow-none {
  overflow: hidden;
}

.codeWrapper {
  margin-bottom: 20px;
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.bg-blur {
  position: fixed;
  backdrop-filter: blur(1em);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
}

.sharebox.component {
  & button {
    height: 30px;
    border-radius: 3px;

    & .copylink {
      text-shadow: 2px 2px $black;
    }
  }

  & .social-icon {
    border-radius: 3px;
  }
}

.commentbox.component {
  & .w-75 {
    & p {
      font-size: 13px;
    }
  }
}

.divider {
  padding: 5px;
}

.divider.ontology-module {
  margin-bottom: 30px;
}
.description {
  padding: 0rem 1.8rem;
}

.license-hdr {
  background-color: $dark-grey;
  padding: 20px;

  & p {
    color: white;
    font-size: 18px;
  }

  & .copytext {
    color: $mid-grey;
    margin-left: 5px;
  }
}

.action_bar.component {
  & .js-btn {
    padding: 10px 15px;
  }

  & .result-btn {
    padding: 10px 15px;
  }

  & .result-btn:hover {
    transition: 0.3s;
    border-color: $blue-light-bg;
    background-color: $blue-light-bg;
  }
}

.cover-component {
  padding: 10px;
  background-color: $black;

  & img {
    width: 100%;
  }
}

.post-container-component {
  display: flex;
  flex-wrap: wrap;
  background-color: $dark-grey;
  padding: 20px;
  border-radius: 10px;

  & .post-content-area {
    width: 100%;
  }

  & .post-right-sidebar {
    width: 30%;
    margin-left: 50px;
  }
}

.cursor {
  cursor: pointer;
}

.text-sm {
  font-size: 13px;
}

.text-md {
  font-size: 16px;
}
