$dark: #333;
$darcula-bg: #282a36;
$black: #000;
$blue: #19304b;
$blue-dark: #162d4c;
$blue-buttons: #18a0fb;
$blue-light-bg: #00bce4;
$blue-lighter: #60d1fa;
$dark-bg: #252830;
$dark-blue: #101e2c;
$dark-blue2: #1a1d23;
$dark-grey: #2c303a;
$dark-secondary: #131417;
$dark2: #1e1f26;
$dashboard-bg: #353535;
$green: #0dd27d;
$green-dark: #3ad179;
$green-highlight: #31bb6b;
$light-grey: #b5b9c4;
$light-mid-grey: #6c7185;
$main-bg: #d6dee1;
$mid-grey: #5a5f72;
$mid-grey2: #444857;
$mod-ont-input-focus: #2d364c;
$secondary-color: #41d0f4;
$mellow-yellow: #f9da7b;
$white: #fff;
$error: #f77286;
$mid-purple: #e10098;
$light-blue: #02abf2;
$light-purple: #ff47c3;

$page-description-background: #15161a;

// fonts
$inter: 'Inter', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$rajdhani: 'Rajdhani', sans-serif;

// footer scss document
$brand-color: #02abf2;
$text1: #fff;
$text1_80: rgba($text1, 0.8);
$grey1: #131417;
$grey1_80: rgba($grey1, 0.92);
$grey2: #1e1f26;
$grey_1: #17181c;
$button-color1: #18a0fb;
$button-color2: #2c303a;
$paragraph-body: rgba(0, 0, 0, 0.8);
$headers-body: #000;

//tailwind 3 colors: B91C1C
$amber-50: #fffbeb;
$amber-700: #ffa000;
$emerald-50: #ecfdf5;
$emerald-700: #00ab7a;
$red-50: #fef2f2;
$red-700: #b91c1c;
$sky-50: #f0f9ff;
$sky-700: #0369a1;

$warningBg: $amber-700;
$successBg: $emerald-700;
$errorBg: $red-700;
$infoBg: $sky-700;
$warningText: $amber-50;
$successText: $emerald-50;
$errorText: $red-50;
$infoText: $sky-50;
$borderRadius: 5px;

// let base = `p-4 my-4 border `;
// let styles = {
// 	info: base + 'bg-sky-700 text-sky-50 border-sky-700',
// 	warning: base + 'bg-amber-700 text-amber-50 border-amber-700',
// 	success: base + 'bg-emerald-700 text-emerald-50 border-emerald-700',
// 	error: base + 'bg-red-700 text-red-50 border-red-700',
// };

//since we designed this for desktop first, we will scale down to mobile
// example: @media only screen and (max-width: $screenPhone) { ... } for phones
$screenPhone: 480px;
$screenTablet: 768px;
$screenMedium: 1024px;
$screenLarge: 1200px;
//desktop is anything above
